<template>
  <div class="invoiceInvoice">
    <!-- <nav-bar title="发票信息" @click-left="$router.go(-1)" left-arrow v-if="isWeixin_status == false" /> -->
    <nav-bar title="发票信息" />
    <field label="发票类型">
      <template slot="input">
        <vant-radio-group v-model="type" direction="horizontal" class="yihang">
          <vant-radio name="1">个人或事业单位</vant-radio>
          <vant-radio name="2">企业</vant-radio>
        </vant-radio-group>
      </template>
    </field>
    <field v-model="tax_name" label="发票抬头" placeholder="抬头名称或极速开票6位代码(必填)" />
    <template v-if="type == '2'">
      <field v-model="tax_id" label="税号" placeholder="纳税人识别号或社会统一征信代码(必填)" />
      <div class="interval"></div>
      <field v-model="bank" label="开户银行" />
      <field v-model="bank_account" label="银行账户" />
      <field v-model="address" label="企业地址" />
      <field v-model="mobile" label="电话号码" />
    </template>
    <cell center title="设为默认地址">
      <vant-Switch v-model="checked" slot="right-icon" size="24" />
    </cell>
    <div class="ticket-btn">
      <vant-button
        clock
        block
        type="danger"
        class="margin-top-set"
        @click="addReceipt"
        v-if="ifInfor"
      >跳过进店填写</vant-button>
      <vant-button
        clock
        block
        type="primary"
        class="margin-top-set"
        @click="addReceipt"
        v-if="ifInfor"
      >确定</vant-button>
      <vant-button
        clock
        type="block"
        size="large"
        class="margin-top-set"
        @click="editReceipt"
        v-else
      >修改</vant-button>
    </div>
  </div>
</template>

<script>
import { NavBar, Field, RadioGroup, Radio, Cell, Switch, Button } from "vant";
import Creditshop from "@/api/creditshop";
export default {
  components: {
    NavBar,
    Field,
    vantRadioGroup: RadioGroup,
    vantRadio: Radio,
    Cell,
    vantSwitch: Switch,
    vantButton: Button,
  },
  data() {
    return {
      ifInfor: true, // false 没有开票信息
      type: "1",
      checked: false,
      tax_name: "",
      tax_id: "",
      email: "",
      bank: "",
      bank_account: "",
      mobile: "",
      address: "",
    };
  },
  mounted() {
    if (this.$route.query.hasOwnProperty("InvoiceId")) {
      this.getdetil(this.$route.query.InvoiceId);
    }
  },
  methods: {
    async editReceipt() {
      try {
        const res = await Creditshop.editReceipt(
          this.$route.query.InvoiceId,
          this.type,
          this.tax_name,
          this.tax_id,
          this.bank,
          this.bank_account,
          this.mobile,
          this.address,
          this.checked ? 1 : 0
        );
        if (res.code == 200) {
          this.$toast(res.msg);
        } else {
          throw res.msg;
        }
      } catch (error) {
        this.$toast(error || "网络错误");
      }
    },
    async addReceipt() {
      try {
        const res = await Creditshop.addReceipt(
          this.type,
          this.tax_name,
          this.tax_id,
          this.bank,
          this.bank_account,
          this.mobile,
          this.address,
          this.checked ? 1 : 0
        );
        if (res.code == 200) {
          this.$toast(res.msg);
          this.$emit("set_receipt", res.data.id, this.type);
        } else {
          throw res.msg;
        }
      } catch (error) {
        this.$toast(error || "网络错误");
      }
    },
    async getdetil(id) {
      try {
        const res = await Creditshop.invoiceIdDetail(id);
        this.ifInfor = false;
        this.type = String(res.data.type);
        this.tax_name = res.data.tax_name;
        this.tax_id = res.data.tax_id;
        this.bank = res.data.bank;
        this.bank_account = res.data.bank_account;
        this.mobile = res.data.mobile;
        this.address = res.data.address;
        this.checked = res.data.is_default == 1 ? true : false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.invoiceInvoice {
  height: 70vh;
  background: #f3f3f3;

  .yihang {
    display: flex;
  }
  .interval {
    height: 10px;
  }
  .margin-top-set {
    margin-top: 20px;
  }
  .ticket-btn {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
  }
}
</style>

